@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.App {
  min-height: 100vh;
  justify-items: center;
  align-items: start;
  background-image: url(./ezgif-3-f2084fdb1c.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  flex-direction: column;
}

.App-logo {
  pointer-events: none;
  height: 10vh;
  margin-top: 10vh;
}

.App-logo-very-small {
  pointer-events: none;
  height: 25px;
  margin-top: 7px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.fork-icon {
  pointer-events: none;
  height: 35px;
}

@media screen and (max-width:900px) {
  .ReactModal__Content {
    left: 50vw !important;
    min-width: 80vw;
    margin-top: 10vh !important;
  }
  .Back-button-modal {
    min-width: 70vw;
  }
}

@media screen and (max-width:900px) {
  .App-logo {
    pointer-events: none;
    height: 10vh;
    margin-top: 4vh;
  }
  .Main-container-Form {
    max-width: 95vw;
    min-width: 60vw;
    display: contents;
    align-items: flex-start;
  }
  .Input-form {
    max-width: 95vw;
  }
  .Button-container {
    max-width: 95vw;
    min-width: 95vw;
  }
  .Button-container-Restart {
    max-width: 95vw;
    min-width: 95vw;
  }

  .Start-button {
    min-width: 40vw;
  }

  .Back-button {
    min-width: 40vw;
  }

  .help-button {
    min-width: 79vw;
  }
}

.App-logo-small {
  pointer-events: none;
  height: 7vh;
  margin-top: 3vh;
}

.Check-mark {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.Check-mark-first {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.Check-mark-last {
  display: inline;
  margin-right: 10px;
  width: 17px;
  height: 17px;
}

.Pitch {
  color: #DE985F;
  text-align: center;
  align-self: center;
  padding: 10px;
  font-family: 'Anton', serif;
}

.Value-prediction {
  color: #DE985F;
  font-family: 'Anton', serif;
  font-size: 40px;
  margin: 0;
}

.Description {
  color: white;
  padding: 10px;
  font-family: 'Arial', serif;
}

.Description-Prediction {
  color: white;
  height: 20px;
  font-family: 'Arial', serif;
  font-size: 13px;
}

.Description-form {
  color: white;
  height: 27px;
  font-family: 'Arial', serif;
  font-size: 13px;
}

.hint {
  color: white;
  padding: 10px;
  font-family: 'Arial', serif;
  font-size: 18px;
}

.hint-first {
  max-height: 40px;
  color: white;
  padding-left: 10px;
  font-family: 'Arial', serif;
  font-size: 18px;
}

.Start-button {
  padding: 20px;
  background-color: #2d2d2d;
  color: #58ff7d;
  margin: 10px;
  border: #58ff7d solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 15vw;
  font-family: 'Anton', serif;
}

.Start-button-wide {
  padding: 20px;
  background-color: #2d2d2d;
  color: #58ff7d;
  margin: 10px;
  border: #58ff7d solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 210px;
  font-family: 'Anton', serif;
}

.terrain-active {
  background-color: #2d2d2d;
  color: #58ff7d;
  vertical-align: center;
  align-items: center;
  padding-left: 56px;
  padding-right: 60px;
  margin: 6px;
  min-height: 100px;
  align-self: center;
  min-width: 220px;
  border: #58ff7d solid 10px;
  border-radius: 30px;
  font-size: 20px;
  width: 160px;
  font-family: 'Anton', serif;
}

.setting-suggestion {
  background-color: #2d2d2d;
  color: #58ff7d;
  text-align: center;
  vertical-align: center;
  align-items: center;
  margin: 10px;
  padding-bottom: 15px;
  max-height: 130px;
  border: #58ff7d solid 5px;
  border-radius: 5px;
  max-width: 130px;
  min-width: 130px;
  font-family: 'Anton', serif;
}

.Main-container-prediction {
  display: flex;
  flex-direction: row;
  max-width: 90vw;
  align-items: center;
  justify-content: space-between;
}

.terrain-deactivated {
  background-color: #2d2d2d;
  color: white;
  vertical-align: center;
  align-items: center;
  padding-left: 56px;
  padding-right: 60px;
  margin: 6px;
  min-width: 220px;
  justify-content: center;
  border: darkgrey solid 10px;
  border-radius: 30px;
  min-height: 100px;
  font-size: 20px;
  width: 160px;
  font-family: 'Anton', serif;
}

.Back-button {
  padding: 20px;
  background-color: #2d2d2d;
  color: white;
  margin: 10px;
  border: darkgrey solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 15vw;
  font-family: 'Anton', serif;
}

.Back-button-modal {
  padding: 20px;
  background-color: #2d2d2d;
  color: white;
  align-self: center;
  margin: 10px;
  border: darkgrey solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 15vw;
  font-family: 'Anton', serif;
}

.ReactModal__Overlay {
  background-color: rgb(222, 152, 95, 0.75) !important;
}

.Close-help-button {
  padding: 20px;
  background-color: #2d2d2d;
  color: white;
  margin: 10px;
  border: darkgrey solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 15vw;
  align-self: center;
  font-family: 'Anton', serif;
}

.help-button {
  padding: 20px;
  background-color: #2d2d2d;
  color: white;
  margin: 10px;
  border: darkgrey solid 3px;
  border-radius: 7px;
  font-size: 15px;
  width: 28.75vw;
  font-family: 'Anton', serif;
}

.List {
  color: #FFFFFF;
  font-family: 'Anton', serif;
  margin-bottom: 50px;
  font-size: 30px;
}

.List-selected {
  color: #FFFFFF;
  font-size: 22px;
  font-family: 'Anton', serif;
  margin: 25px;
}

.List-selected-last {
  color: #FFFFFF;
  flex-direction: column;
  display: flex;
  margin-left: 20px;
  font-size: 17px;
  font-family: 'Anton', serif;
  margin-top: 20px;
}

.Input {
  padding: 10px;
  background-color: #1E1E1E;
  color: white;
  margin: 10px;
  border: darkgrey solid 1px;
  border-radius: 7px;
  font-size: 30px;
  max-width: 90vw;
  font-family: 'Arial', serif;
}

.Input-form {
  padding-top: 10px;
  background-color: #1E1E1E;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  border: darkgrey solid 1px;
  border-radius: 7px;
  min-width: 60vw;
  font-size: 30px;
  font-family: 'Arial', serif;
}

.App-header {
  display: flex;
  max-width: 100vw;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-Form {
  display: flex;
  background-color: #2d2d2d;
  min-width: 100vw;
  border-top: #DE985F;
  border-width: 10px;
  border-top-style: solid;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-hidden {
  display: none;
  max-width: 100vw;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100vw;
  min-width: 100vw;
  height: 40px;
  background-color: #1E1E1E;
}

.Button-container {
  display: flex;
  padding-top: 3vh;
  max-width: 30vw;
  align-self: center;
  padding-bottom: 3vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Button-container-Restart {
  display: flex;
  padding-top: 3vh;
  max-width: 30vw;
  padding-bottom: 3vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Main-container {
  padding: 10px;
  flex-direction: row;
  display: contents !important;
  align-items: center;
  justify-content: center;
}

.Main-container-Form {
  max-width: 80vw;
  padding: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-row {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
}

.Form-Feedback {
  padding: 10px;
  flex-direction: column;
  display: contents !important;
  align-items: center;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  z-index: 5;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  position: absolute;
  display: grid;
  z-index: 5;
  margin-top: 30vh;
  align-self: center;
  justify-content: center;
  align-items: center;
  height: 350px;
}
